
import Vue from 'vue';
import { Helpers } from '@/utils';
import {
  add as dateFnsAdd,
  formatISO as dateFnsFormatISO,
  set as dateFnsSet,
  startOfMonth as dateFnsStartOfMonth,
  startOfWeek as dateFnsStartOfWeek,
  sub as dateFnsSub,
} from 'date-fns';
import { gallery as galleryServices, user as userService } from '@/services';
import {
  BaseCard,
  BasePagination,
  BaseLoading,
} from '@/components/BaseComponents';

import {
  WebTVMenu,
} from '@/components/WebTV';

import {
  GalleryBasicVideoData,
} from '@/components/Gallery';

export default Vue.extend({
  name: 'ViewChannel',

  components: {
    BaseLoading,
    BaseCard,
    WebTVMenu,
    GalleryBasicVideoData,
    BasePagination,
  },
  props: {
    encryptedGalleryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      categoryItems: [{ text: 'all', value: 0 }] as Views.ViewGallery.CategoryItems[],
      currentPage: 1,
      searchValue: '',
      selectedCategory: 0,
      selectedPeriod: 'ALL' as Views.ViewGallery.Periods,
      totalVideosAvailable: 0,
      numberOfPagesAvailable: 0,

      loading: true,
      facebookValue: '',
      instagramValue: '',
      twitterValue: '',
      youtubeValue: '',
      showMenu: false,
      aboutValue: '',
      secret: '',
      hash: '',
      selectedVideos: [{}],
      videosPerPage: 0,

      customerId: [] as any,
      hotsiteMediaSocialId: '',
      idPlan: '',
      plan: '',
      urlPlayer: '',

      timeOutId: 0,

      videos: [] as Views.ViewGallery.Video[],
    };
  },

  computed: {
    userState() {
      return this.$store.state.user;
    },
  },

  watch: {
    currentPage() {
      this.getVideos(false);
    },
  },

  mounted() {
    this.getCategories();
    this.getVideosPerPage();
    this.getVideos();
    setTimeout(() => {
      this.fetchCustomerPlan(this.categoryItems[1].value);
    }, 500);
  },

  methods: {
    async getVideosPerPage(): Promise<Services.Gallery.Response.GetColors | 'error'> {
      const encryptedGalleryId = window.location.pathname.split('/')[1];

      return galleryServices.getColors(encryptedGalleryId)
        .then(({ data }) => {
          this.videosPerPage = data.videos_per_page;
          return data;
        })
        .catch((error) => {
          console.error('App - method: "getVideosPerPage()" ', error);
          return 'error';
        });
    },
    addVideoToStore(video: Views.ViewGallery.Video) {
      this.$store.dispatch('video/add', video);
    },
    async getCategories() {
      return galleryServices.getCategories(this.encryptedGalleryId)
        .then(({ data: categories }) => {
          this.categoryItems.push(
            ...categories.map((category) => ({
              text: category.name,
              value: category.id,
            })),
          );
        })
        .catch((error) => {
          console.error('ViewGallery - method: "getCategories()" ', error);

          this.showNotification('error_getting_categories', 'error');
        });
    },
    getPeriod(period: Views.ViewGallery.Periods) {
      const handleDate = (date: Date) => (
        dateFnsFormatISO(dateFnsSet(date, { hours: 0, minutes: 0, seconds: 0 }))
      );

      let beginDate: string | undefined;
      let endDate: string | undefined;

      switch (period) {
        case 'ALL': default: break;
        case 'LAST_7_DAYS':
          beginDate = handleDate(dateFnsSub(new Date(), { weeks: 1 }));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'LAST_30_DAYS':
          beginDate = handleDate(dateFnsSub(new Date(), { months: 1 }));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'THIS_MONTH':
          beginDate = handleDate(dateFnsStartOfMonth(new Date()));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'THIS_WEEK':
          beginDate = handleDate(dateFnsStartOfWeek(new Date()));
          endDate = handleDate(dateFnsAdd(new Date(), { days: 1 }));
          break;
        case 'YESTERDAY':
          beginDate = handleDate(dateFnsSub(new Date(), { days: 1 }));
          endDate = handleDate(new Date());
          break;
      }

      return { begin_date: beginDate, end_date: endDate };
    },
    async getVideosService(
      payload: Services.Gallery.GetVideos,
    ): Promise<Services.Gallery.Response.GetVideos | 'error'> {
      return galleryServices.getVideos(payload)
        .then(({ data }) => data)
        .catch((error) => {
          console.error('ViewGallery - method: "getVideos()" ', error);

          this.showNotification('error_getting_videos', 'error');

          return 'error';
        });
    },
    async getVideos(updateNumberOfPagesAvailable = true) {
      const categoryId = this.selectedCategory > 0 ? this.selectedCategory : undefined;
      const search = this.searchValue.trim() !== '' ? this.searchValue.trim() : undefined;

      const response = await this.getVideosService({
        ...this.getPeriod(this.selectedPeriod),
        category_id: categoryId,
        encryptedGalleryId: this.encryptedGalleryId,
        page: this.currentPage,
        search,
      });

      if (response === 'error') {
        this.loading = false;

        return;
      }

      this.totalVideosAvailable = response.total;
      this.videos = response.videos;

      if (updateNumberOfPagesAvailable) {
        this.numberOfPagesAvailable = (
          Math.ceil(this.totalVideosAvailable / this.videos.length)
          || 0
        );
      }

      this.loading = false;
    },
    onChangeCategory(category: number): void {
      this.selectedCategory = category;

      this.currentPage = 1;

      this.getVideos();
    },
    onChangePeriod(period: Views.ViewGallery.Periods): void {
      this.selectedPeriod = period;

      this.currentPage = 1;

      this.getVideos();
    },
    onChangeSearch(value: string): void {
      this.currentPage = 1;
      this.searchValue = value;

      clearTimeout(this.timeOutId);

      this.timeOutId = setTimeout(() => this.getVideos(), 500);
    },
    async getCustomerPlan(id: string) {
      const response = await userService.getCustomerPlan(id);
      this.secret = response.data.secret;
      this.hash = response.data.hash_satisfaction;
      this.plan = response.data.name;
      this.customerId = response.data.id_customers;
    },
    async fetchCustomerPlan(id: number) {
      try {
        const response = await userService.getCustomerPlanId(id);
        this.idPlan = await response.data.selected_plan_id;
        await this.getCustomerPlan(this.idPlan);
        await this.fetchHotsiteMediaSocial(this.idPlan);
        await this.playerUrl();
        this.loading = false;
      } catch (error) {
        console.error('Error fetching hotsiteMediaSocial:', error);
      }
    },
    async fetchHotsiteMediaSocial(id: string) {
      try {
        const response = await userService.getHotsiteMediaSocial(id);
        this.aboutValue = response.data.about;
        this.facebookValue = response.data.facebook;
        this.instagramValue = response.data.instagram;
        this.twitterValue = response.data.twitter;
        this.youtubeValue = response.data.youtube;
        this.hotsiteMediaSocialId = id;
      } catch (error) {
        console.error('Error fetching hotsiteMediaSocial:', error);
      }
    },
    formatDate(date: string) {
      return Helpers.formatDate(this.$root.$i18n.locale as 'en-US' | 'pt-BR', date);
    },
    showNotification(text: string, type: Store.Notification.Type) {
      this.$store.dispatch('notification/create', { text, type });
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    onClickVideo(video: Views.ViewGallery.Video) {
      this.addVideoToStore(video);

      this.$router.push({ name: 'Video' });
    },
    playerUrl() {
      const planUrls: any = {
        AVJ: {
          production: 'https://player.jmvstream.com/avj/',
          sandbox: 'https://player-sandbox.jmvstream.com/avj/',
          staging: 'https://player-staging.jmvstream.com/avj/',
        },
        LVW: {
          production: 'https://player.jmvstream.com/lvw/',
          sandbox: 'https://player-sandbox.jmvstream.com/lvw/',
          staging: 'https://player-staging.jmvstream.com/lvw/',
        },
      };

      const planKey = this.plan.split('-')[0];
      const environment = process.env.VUE_APP_NODE_ENV;

      if (planKey && planUrls[planKey] && planUrls[planKey][environment]) {
        this.urlPlayer = `${planUrls[planKey][environment]}${this.secret}`;
      }
    },
  },
});
