<template>
  <input
    v-model="value"
    :type="type"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
    class="text-input"
  />
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    value: String,
    type: {
      type: String,
      default: 'text',
    },
    placeholder: String,
  },
};
</script>

<style scoped>
.text-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}
</style>
