
import Vue from 'vue';

export default Vue.extend({
  name: 'WebTVMenu',

  props: {
    facebookLink: {
      type: String,
      default: '',
    },
    instagramLink: {
      type: String,
      default: '',
    },
    twitterLink: {
      type: String,
      default: '',
    },
    youtubeLink: {
      type: String,
      default: '',
    },
    about: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      menuOpen: true,
    };
  },

  methods: {
  },
});
